import styled from 'styled-components';

import { FlexColumn, FlexRow, FlexRowEnd, FlexRowSpaceBetween, FlexColumnCenterItemsCenter } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';

import device from '@/styles/device';

import { ReactComponent as UndrawTerms } from '@/assets/illustrations/undraw_terms.svg';

export const StyledContainer = styled(FlexColumn)`
  width: 100%;
  // margin-left: 2.5rem;
  // margin-right: 2.5rem;
  position: relative;

  @media ${device.laptop} {
    margin: 0rem;
  };
`;

export const StyledTitleWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 4rem;
`;

export const StyledButtonsContainer = styled(FlexRowEnd)`
  margin-top: 2rem;
  gap: 1rem;
`;

export const StyledTableInfo = styled(FlexRowSpaceBetween)`
  // margin-top: 1rem;
  position: relative;
`;

export const StyledNewCustomerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: -0.1rem;
`;

export const StyledAccountActivationWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: -1rem;
`;

export const StyledCustomersInfo = styled(FlexRow)`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5px;
  gap: 2rem;
`;

// EMPTY STATE

export const StyledEmptyStateWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 12rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledUndrawTerms = styled(UndrawTerms)`
  height: 200px;
  margin: 2rem 0;
`;

export const StyledEmptyStateContainer = styled(FlexColumnCenterItemsCenter)`
  max-width: 600px;
  margin: 4rem auto;

  & > * {
    margin-bottom: 1rem;
  }
`;

export const StyledLeftIconButton = styled(Button)`
  justify-content: space-between;

  & > span {
    margin-left: 1.5rem;
  }
`;