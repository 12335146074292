import gql from 'graphql-tag';

export const SEARCH_CUSTOMERS = gql`
  query searchCustomers($term: String) {
    searchCustomers(term: $term) {
      id
      isManualCustomer
      providerCompany {
        id
        name
      }
      seekerCompany {
        id
        name
      }
      latestApplication {
        status
        assignedTo {
          name
        }
      }
    }
  }
`;
