import React from 'react';

import {
  StyledUserInfoContainer,
  StyledName,
  StyledEmail,
  StyledCompany,
} from './styled';


interface IProps {
  name: string;
  email: string;
  currentCompany?: string
}

export const UserInfo = ({ name, email, currentCompany}: IProps) => {
  return (
    <StyledUserInfoContainer>
      { currentCompany && (
        <StyledCompany>
          {currentCompany}
        </StyledCompany>
      )}
      <StyledName>
        {name}
      </StyledName>
      <StyledEmail>
        {email}
      </StyledEmail>
    </StyledUserInfoContainer>
  );
};
