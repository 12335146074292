import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { flow, keyBy, mapValues } from "lodash/fp";

import {
  ICustomField,
  ICustomFieldAnswer,
  ICustomFieldMultipleChoiceValue,
  ICustomFieldValue,
  ICustomFieldYesNoToggleFileValue,
  ICustomFieldYesNoToggleTextValue,
  ICustomYesNoToggleField,
  IForToggle
} from "@/types/customField";
import { IFileCustomFieldFormValue } from "@/types/onboardingValues";

import { CUSTOM_FIELD_TYPES, CUSTOM_TOGGLE_FIELD_TYPES } from "@/constants";

// REFERRAL DATA TO FORMIK VALUES

const formatYesNoToggleCustomFieldToFormInitialValue = (
  toggleType: string,
  customFieldAnswer: ICustomFieldAnswer | undefined
) => ({
  checked: (customFieldAnswer && customFieldAnswer.isToggled !== null) ? customFieldAnswer.isToggled : null,
  ...(toggleType === CUSTOM_TOGGLE_FIELD_TYPES.TEXT
    ? { text: customFieldAnswer?.text ?? '' }
    : { files: customFieldAnswer?.file ? [{
      fileId: customFieldAnswer.file.id,
      path: customFieldAnswer.file.originalFilename,
      name: customFieldAnswer.file.originalFilename,
      signedUrl: customFieldAnswer.file.signedUrl,
    }] : [] }
  ),
});

const formatCustomFieldToFormInitialValue = (
  customField: ICustomField,
  customFieldAnswer: ICustomFieldAnswer | undefined
) => {
  switch (customField.type) {
  case CUSTOM_FIELD_TYPES.FILE_UPLOAD:
    return customFieldAnswer?.file ? [{
      fileId: customFieldAnswer.file.id,
      path: customFieldAnswer.file.originalFilename,
      name: customFieldAnswer.file.originalFilename,
      signedUrl: customFieldAnswer.file.signedUrl,
    }] : [];

  case CUSTOM_FIELD_TYPES.TEXT:
  case CUSTOM_FIELD_TYPES.DROPDOWN:
  case CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION:
    return customFieldAnswer?.text ?? '';

  case CUSTOM_FIELD_TYPES.TOGGLE:
    return formatYesNoToggleCustomFieldToFormInitialValue(
      (customField as ICustomYesNoToggleField).yesToggle.type || '',
      customFieldAnswer
    );

  case CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE:
    return {
      selections: customFieldAnswer?.selections ?? []
    };

  case CUSTOM_FIELD_TYPES.CURRENCY:
    return customFieldAnswer?.currency.amount ?? null;

  default:
    return '';
  }
};

export const mapCustomFieldsToFormInitialValues = (
  customFields: ICustomField[],
  customFieldAnswers: ICustomFieldAnswer[]
) => customFields
  // Reduce Custom Fields array to Formik object.
  .reduce(
    (acc, customField) => {
      const customFieldAnswer = customFieldAnswers.find((answer) => customField.id === answer.customField.id)
      return { ...acc, [`${customField.id}`]: formatCustomFieldToFormInitialValue(customField, customFieldAnswer) }
    }, {}
  );

// VALIDATION

export const getValidatorForCustomField = ({ type, required, yesToggle }: ICustomField) => {
  let baseValidator;

  switch (type) {
  case CUSTOM_FIELD_TYPES.FILE_UPLOAD:
    baseValidator = Yup.array();
    return required
      ? baseValidator.min(1, DEFAULT_ERROR_MESSAGES.REQUIRED)
      : baseValidator;

  case CUSTOM_FIELD_TYPES.TOGGLE:
    return Yup.object().shape({
      checked: required ? Yup.boolean().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.boolean().nullable(),
      ...((yesToggle as IForToggle).type === 'text' ? {
        text: Yup.string().when('checked', {
          is: (value: boolean) => value,
          then: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
        })
      } : {
        files: Yup.array().when('checked', {
          is: (value: boolean) => value,
          then: Yup.array().min(1, DEFAULT_ERROR_MESSAGES.REQUIRED)
        })
      }),
    })

  case CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE:
    baseValidator = Yup.array()
    if (required) {
      baseValidator = baseValidator.min(1, DEFAULT_ERROR_MESSAGES.REQUIRED)
    }
    return Yup.object().shape({
      selections: baseValidator
    })

  case CUSTOM_FIELD_TYPES.CURRENCY:
    baseValidator = Yup.number().nullable();
    return required
      ? baseValidator.required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      : baseValidator;

  default:
    baseValidator = Yup.string().max(255, 'Can\'t be longer than 255 characters');
    return required
      ? baseValidator.required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      : baseValidator;
  }
};

export const getValidationForCustomFields = (customFields: ICustomField[]) => {
  const fieldValidators = flow(
    keyBy('id'),
    mapValues(getValidatorForCustomField),
  )(customFields);

  return fieldValidators;
};

// FORMIK VALUES TO REQUEST VARIABLES

export const mapFormValuesToFieldAnswers = (
  formValues: ICustomFieldValue,
  customFields: ICustomField[]
) => Object.entries(formValues).map((customFieldAnswer) => {
  const customFieldId = customFieldAnswer[0]
  const type = customFields.find((customField) => customField.id === customFieldId)?.type;

  switch (type) {
  case CUSTOM_FIELD_TYPES.FILE_UPLOAD:
    return {
      customFieldId,
      fileIds: (customFieldAnswer[1] as IFileCustomFieldFormValue[]).map((file) => file.fileId)
    };

  case CUSTOM_FIELD_TYPES.TEXT:
  case CUSTOM_FIELD_TYPES.DROPDOWN:
  case CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION:
    return {
      customFieldId,
      text: customFieldAnswer[1]
    };

  case CUSTOM_FIELD_TYPES.TOGGLE:
    return {
      customFieldId,
      isToggled: (customFieldAnswer[1] as ICustomFieldYesNoToggleTextValue).checked,
      ...((customFieldAnswer[1] as ICustomFieldYesNoToggleTextValue).text
        && { text: (customFieldAnswer[1] as ICustomFieldYesNoToggleTextValue).text }),
      ...((customFieldAnswer[1] as ICustomFieldYesNoToggleFileValue).files
        && { fileIds: (customFieldAnswer[1] as ICustomFieldYesNoToggleFileValue).files.map((file) => file.fileId) }),
    }
    ;

  case CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE:
    return { customFieldId, selections: (customFieldAnswer[1] as ICustomFieldMultipleChoiceValue).selections };

  case CUSTOM_FIELD_TYPES.CURRENCY:
    return customFieldAnswer[1] ? {
      customFieldId,
      text: 'currency',
      currency: { amount: customFieldAnswer[1], currency: "USD" }
    } : {
      customFieldId,
      text: 'currency',
      currency: { amount: 0, currency: "USD" }
    };

  default:
    return null;
  }
})
  .filter((answer) => answer);
