import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { H4, Caption } from '@/components/designSystem/Typography';

import device from '@/styles/device';
import { Flex, FlexColumn, FlexRowItemsCenter } from '@/components/designSystem/Layout';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { ReactComponent as ToggleChevron } from '@/assets/toggle_chevron.svg';

export const NavContainer = styled.aside`
  grid-area: nav;
  // padding-top: ${(props) => props.theme.spacing.site.top};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* justify-content: space-between; */
  border-right: 1px ${(props) => props.theme.color.blackTrans8} solid;
  max-height: 100vh;
  position: sticky;
  top: 0;
  z-index: 2;

  @media ${device.laptopL} {
    grid-template-columns: 250px 1fr;
  };

  @media ${device.mobileXL} {
    display: none;
  };
`;

export const MobileContainer = styled.div`
  display: none;
  grid-area: nav;

  @media ${device.mobileXL} {
    display: flex;    
    border-bottom: 1px solid #e5e7eb;
    z-index: 11;
  };
`;

interface IMobileNavProps {
  isVisible: boolean,
}

export const MobileNav = styled(FlexColumn)<IMobileNavProps>`
  position: absolute;
  background-color: white;
  // height: 37.7rem;
  width: 100%;
  z-index: 2;
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(-120%)')};
  transition: transform 0.3s ease-in-out;
  border-bottom: 1px ${(props) => props.theme.color.blackTrans8} solid;
  top: 4rem;
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s, visibility .4s;
  overflow: hidden;
  background: black;
  z-index: 1;

  &.active {
    opacity: 0.5;
    visibility: visible;
  }
`;

interface IBurgerButtonProps {
  isMenuVisible: boolean,
}

export const StyledBurgerButton = styled.button<IBurgerButtonProps>`
  position: absolute;
  top: 1.2rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 1.2rem;
  height: 1.2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.2rem;
    height: 0.15rem;
    background: ${({ theme }) => theme.color.black};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isMenuVisible }) => (isMenuVisible ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ isMenuVisible }) => (isMenuVisible ? '0' : '1')};
      transform: ${({ isMenuVisible }) => (isMenuVisible ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      width: ${({ isMenuVisible }) => (isMenuVisible ? '1.2rem' : '0.6rem')};
      transform: ${({ isMenuVisible }) => (isMenuVisible ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

interface INavGroupProps {
  index: number,
}

export const NavGroupContainer = styled(FlexColumn)<INavGroupProps>`
  margin: 1.5rem 0rem;
`;

export const StyledDivider = styled.div`
  align-self: center;
  height: 1px;
  width: 80%;
  background-color: ${({ theme }) => theme.color.blackTrans16};
`;

interface INavItemProps {
  isActive?: boolean,
  active?: boolean,
}

export const StyledLink = styled.a<INavItemProps>`
  padding: 0.75rem 0 0.75rem ${(props) => props.theme.spacing.sidebar.left};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.beige};
  }

  &:hover > div > svg > path {
    fill: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  &:hover > div > .nav-item-text {
    color: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  @media ${device.laptop} {
    padding-left: 1.75rem;
  };
`;

export const StyledNavLink = styled(NavLink)<INavItemProps>`
  padding: 0.75rem 0 0.75rem ${(props) => props.theme.spacing.sidebar.left};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.color.beige};
  }

  &:hover > div > svg > path {
    fill: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  &:hover > div > .nav-item-text {
    color: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  @media ${device.laptop} {
    padding-left: 1.75rem;
  };
`;

export const StyledNavBlock = styled(FlexRowItemsCenter)<INavItemProps>`
  position: relative;
  white-space: nowrap;

  & > svg > path {
    fill: ${({ theme, isActive }) => (isActive ? theme.color.primary : theme.color.text.secondary)};
  }

  &:hover > svg > path {
    fill: ${({ theme, isActive }) => !isActive && theme.color.text.primary}
  }

  &:hover > .nav-item-text {
    color: ${({ theme, isActive }) => !isActive && theme.color.text.primary}
  }

  &:hover > .tooltip {
    visibility: visible;
    left: 190%;
    transition: left 0.4s;
  }
`;

export const StyledActiveBlock = styled.div`
  min-height: 32px;
  height: 100%;   
  width: 0.4rem;
  background-color: ${(props) => props.theme.color.primary};
  border-radius: 0.5rem 0 0 0.5rem;

  @media ${device.laptop} {
    display: none;
  };
`;

export const NavItemText = styled(H4)<INavItemProps>`
  padding-left: 0.75rem;
  color: ${({ theme, isActive }) => (isActive ? theme.color.primary : theme.color.text.secondary)};

  @media ${device.laptop} {
    display: none;
  };

  @media ${device.mobileXL} {
    display: block;
  };
`;

export const StyledTooltip = styled(Caption)`
  visibility: hidden;
  background-color: ${({ theme }) => theme.color.greyDark};
  color: #fff;
  text-align: center;
  padding: 0.75rem;
  white-space: nowrap;
  left: 185%;

  position: absolute;
  z-index: 0; 

  @media ${device.desktop} {
    display: none;
  };

  @media ${device.laptop} {
    display: block;
  };

  @media ${device.tablet} {
    display: block;
  };

  @media ${device.mobileXL} {
    display: none;
  };
`;

export const StyledUserInfoContainer = styled(Flex).attrs({ direction: 'column', gap: '0.3rem' })`
  margin: 0 0.2rem 1.5rem 1.5rem;
  padding: 1rem 0rem;

  @media ${device.laptop} {
    display: none;
  };
`;

export const StyledCompany = styled(Caption)`
  color: ${({ theme }) => theme.color.greyMed};
`;

export const StyledName = styled(Caption)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.greyDark};
  word-wrap: break-word;
`;

export const StyledEmail = styled(Caption)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.greyDark};
  word-wrap: break-word;
`;


export const StyledSelectField = styled(SelectField)`
  width: 190px;
`;

export const StyledCurrentCompanyLogoWrapper = styled.div`
  width: 140px;
  margin-bottom: 1rem;
`

export const NavCollapseItem = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  // @media ${device.laptop} {
  //   padding-left: 1.75rem;
  // };

  @media ${device.mobileXL} {
    padding-left: 0rem;
  };
`;

export const NavChildrenWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 0.25rem;
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: max-height 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;


export const StyledNavCollapseBlock = styled.div`
  padding: 0.75rem 0 0.75rem ${(props) => props.theme.spacing.sidebar.left};
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.color.beige};
  }

  &:hover > div > svg > path {
    fill: ${({ theme }) => theme.color.text.primary}
  }

  & > svg {
    margin-right: 0.5rem;
  }

  & > div {
    display: flex;
    align-items: center;

    & > svg > path {
      fill: ${({ theme }) => theme.color.text.secondary};
    }
  }

  &:hover > .tooltip {
    visibility: visible;
    left: 100%;
    transition: left 0.4s;
  }

  @media ${device.laptop} {
    padding-left: 1.75rem;
  };

  @media ${device.mobileXL} {
    padding-right: 1rem;
  };
`;

export const StyledCollapseToggleIcon = styled(ToggleChevron)<{ isOpen: boolean }>`
  margin-left: 1rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};

  @media ${device.laptop} {
    display: none;
  };

  @media ${device.mobileXL} {
    display: block;
  };
`;

export const StyledNavChildLink = styled(NavLink)<INavItemProps>`
  padding: 0.75rem 0 0.75rem ${({ theme }) => `calc(${theme.spacing.sidebar.left} + 11px)`};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.color.beige};
  }

  &:hover > div > svg > path {
    fill: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  &:hover > div > .nav-item-text {
    color: ${({ theme, isActive }) => (!isActive ? theme.color.text.primary : 'none')}
  }

  @media ${device.laptop} {
    padding-left: 2rem;
  };

  @media ${device.mobileXL} {
    padding-left: ${({ theme }) => `calc(${theme.spacing.sidebar.left} + 11px)`};
  };
`;