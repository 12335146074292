import React, { useEffect } from 'react';
import {
  Router, Route, Redirect, Switch,
} from 'react-router-dom';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { initApolloClient } from '@/graphql/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import { useLocalStorage } from '@rehooks/local-storage';
import history from '@/utils/history';
import { startTracker, recordGraphQL } from '@/utils/openreplay';

import { Auth0Provider } from '@/providers/Auth0/Auth0';
import { initializeSegment } from '@/providers/Segment';
import { UpgradeProvider } from '@/providers/Upgrade/Upgrade';
import { RedirectProvider } from '@/providers/Redirect/Redirect';
import { CustomModalProvider } from '@/providers/Modal';
import { ApplicationStatusProvider } from '@/providers/ApplicationStatus';
import { CompanyProvider } from '@/providers/ChangeCompany';

import { OnboardApp } from './routes/OnboardApp';
import { ProductApp } from './routes/ProductApp';
import { LoginApp } from './routes/LoginApp';

import { StyledToastContainer } from '@/containers/StyledToastContainer/container';
import theme from '@/styles/theme';

import { SENTRY } from '@/app.config';

function applyThemeColors() {
  const root = document.documentElement;

  const themeObject = theme();
  
  root.style.setProperty('--primary-color', themeObject.color.primary);
  root.style.setProperty('--secondary-color', themeObject.color.secondary);
  root.style.setProperty('--brand-color', themeObject.color.brand);
}

applyThemeColors();

Sentry.init({
  dsn: SENTRY.dsn,
  environment: SENTRY.environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsole({
      levels: ['error'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any,
  ],
});

initializeSegment();

const App = () => {
  const [token] = useLocalStorage('token');
  const [companyId] = useLocalStorage('company-id');
  const client = initApolloClient({ token, companyId, recordGraphQL });

  useEffect(() => {
    startTracker();
  }, [])

  return (
    <ThemeProvider theme={theme()}>
      <Router history={history}>
        <RedirectProvider>
          <ApolloProvider client={client}>
            <Auth0Provider>
              <CustomModalProvider>
                <UpgradeProvider>
                  <ApplicationStatusProvider>
                    <CompanyProvider>
                      <Switch>
                        <Redirect exact from='/' to='/dashboard' />
                        <Route path='/signup' component={OnboardApp} />
                        <Route path='/login' component={LoginApp} />
                        <Route path='/' component={ProductApp} />
                      </Switch>
                      <StyledToastContainer
                        position='bottom-right'
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </CompanyProvider>
                  </ApplicationStatusProvider>
                </UpgradeProvider>
              </CustomModalProvider>
            </Auth0Provider>
          </ApolloProvider>
        </RedirectProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
