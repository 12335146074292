import React, { useContext } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_MY_COMPANIES } from '@/graphql/queries/getMyCompanies';

import { Formik, Form } from 'formik';

import { getOr, get } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { useCompany } from '@/providers/ChangeCompany';

import { AutoSave } from '@/components/designSystem/Form/AutoSave';

import {
  StyledUserInfoContainer,
  StyledName,
  StyledEmail,
  StyledCompany,
  StyledSelectField,
  StyledCurrentCompanyLogoWrapper,
} from './styled';
import { PreviewLogo } from '../../../views/ApplicationTemplate/DesignAndBrand/styled';

interface IValues {
  companyId: string,
}

interface IUCompany {
  name: string,
  id: string,
}

export const NavigationUserInfo = () => {
  const { data: userCompaniesData } = useQuery(GET_MY_COMPANIES);
  
  const { companyId, setCompanyId } = useCompany();
  
  const currentCompanyData = useContext(CurrentCompanyContext);

  const userCompanies = getOr([], 'myCompanies', userCompaniesData);

  const currentCompany = getOr('', 'currentCompany.name', currentCompanyData);
  const currentCompanyId = get('currentCompany.id', currentCompanyData) || companyId;
  const currentCompanyLogo = get('currentCompany.brand.logo.signedUrl', currentCompanyData);
  
  const autoChangeCompany = (settings: IValues, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => {
    setCompanyId(settings.companyId);
    setSubmitting(false);
  };

  const initialSettings = { companyId: currentCompanyId };

  return (
    <StyledUserInfoContainer>
      <StyledCurrentCompanyLogoWrapper>
        <PreviewLogo src={currentCompanyLogo} />
      </StyledCurrentCompanyLogoWrapper>
      { userCompanies.length > 0 ? (
        <StyledCompany>
          {currentCompany}
        </StyledCompany>
      ) : false}
      {userCompanies.length > 1 ? (
        <Formik
          initialValues={initialSettings}
          enableReinitialize={true}
          onSubmit={autoChangeCompany}
        >
          <Form>
            <StyledSelectField
              required
              name='companyId'
              label='Companies'
              options={userCompanies.map((uCompany: IUCompany) => ({ label: uCompany.name, value: uCompany.id }))}
            />
            <AutoSave debounceMs={500} showText={false} />
          </Form>
        </Formik>
      )
        : false
      }
    </StyledUserInfoContainer>
  );
};
