import styled from 'styled-components';

interface IDropdownMenuProps {
  isOpen: boolean;
  color?: string;
  fontSize?: string;
  width?: string;
  backgroundColor?: string;
  position?: 'left' | 'right';
  borderWidth?: string;
  borderColor?: string;
  borderStyle?: string;
  borderRadius?: string;
  zIndex?: number;
  upward?: boolean;
}

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const DropdownMenu = styled.ul<IDropdownMenuProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  color: ${({ color }) => color || '#000000'};
  font-size:  ${({ fontSize }) => fontSize || '1rem'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  border: ${({ theme }) => theme.color.border.light};
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
  width: ${({ width }) => width || '10rem'};
  z-index: ${({ zIndex }) => zIndex || 1};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style: none;
  margin: 2px 0 0 0;
  padding: 0;
  ${({ position }) => (position === 'right' ? 'right: 0px;' : 'left: 0px;')};
  ${({ upward }) => (upward ? 'bottom: 2.75rem;' : 'top: 100%;')};
`;

export const DropdownMenuItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;

  &:first-child {
    border-radius: 6px 6px 0px 0px; 
  }

  &:last-child {
    border-radius: 0px 0px 6px 6px; 
  }

  &:hover {
    color: ${({ theme }) => theme.color.text.primary};
    background-color: ${({ theme }) => theme.color.beige};
  }
`;

export const DropdownMenuItemSeparator = styled.div`
  height: 1px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: ${({ theme }) => theme.color.blackTrans16};
`;