import styled, { keyframes } from 'styled-components';

import device from '@/styles/device';

export const dropdownFadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  border-radius: 50px;
  max-width: 768px;
  &:focus-within {
    z-index: 10;
  }
  padding: 0.5rem 1.5rem;

  @media ${device.mobileXL} {
    padding: 0.5rem 1rem;
  };
`;

export const MenuButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0.375rem 2.5rem;
  text-align: left;
  cursor: text;
  border: ${({ theme }) => (theme.color.border.light)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;
  height: 2.7rem;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }

  .icon {
    pointer-events: none;
    position: absolute;
    padding-left: 0.75rem;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`;

export const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 24rem;
  max-height: 30rem;
  background-color: white;
  border-radius: 0.375rem;
  // padding: 0.875rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  animation: ${({ isOpen }) => isOpen && dropdownFadeIn} 0.2s ease-out;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const StyledSearchInputContainer = styled.div`
  position: relative;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.875rem;
`
export const StyledSearchInputForm = styled.form`
  position: relative;
  flex-grow: 1;

  .icon {
    pointer-events: none;
    position: absolute;
    padding-left: 0.75rem;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`
export const StyledCloseButton = styled.button`
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.color.greyDark};

  &:hover {
    color: ${({ theme }) => theme.color.black};
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 1rem;
  border: ${({ theme }) => (theme.color.border.light)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;
  height: 2.7rem;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }

  .icon {
    pointer-events: none;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
  }
`;

export const StyledAdvanceSearchButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 0rem 1rem;
  border-left: ${({ theme }) => (theme.color.border.light)};

  svg {
    margin-right: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
  }
`

export const TagContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0;
`

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 9999px;
  font-size: 0.875rem;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
`;

export const StyledFilterItemList = styled.ul`
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 20rem;
  scroll-padding-bottom: 0.5rem;
  scroll-padding-top: 2.75rem;
  overflow-y: auto;
  padding: 0.875rem;
  gap: 0.5rem;
`

export const StyledFilterItems = styled.li`
  display: grid;
  gap: 0.5rem;
`

export const StyledCategoryHeader = styled.h2`
  padding: 0.625rem 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  background-color: #f3f4f6;
  color: #111827;
`

export const StyledItemList = styled.ul`
  border-top: ${({ theme }) => (theme.color.border.light)};
  list-style: none;
  margin: 0;
  padding: 0;
`

export const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  user-select: none;
  gap: 0.5rem;
  cursor: pointer;
  border-left: 4px solid transparent;
  &:hover {
    background-color: #f0f0f0;
    border-left: 4px solid ${({ theme }) => (theme.color.brand)};
  }
`;

export const StyledListItemNoResult = styled.p`
  border-top: ${({ theme }) => (theme.color.border.light)};
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
`;


// Advance Search Styles

export const AdvancedSearchContainer = styled.div`
  padding: 1rem 0;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
`;

export const InputField = styled.input`
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  padding: 0.375rem;
  background: white;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #1f2937;
  border: 1px solid #d1d5db;

  &:focus {
    outline: none;
    border-color: #4b5563;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #d1d5db;
  gap: 1rem;
`;

export const StyledBlankAvatar = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  height: 2rem;
  width: 2rem;
  background-color: #d3d3d3;
`