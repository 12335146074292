import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { H4, Caption } from '@/components/designSystem/Typography';

import { ReactComponent as LogoMark } from '@/assets/logo_mark.svg';

import device from '@/styles/device';
import { Flex, FlexColumn, FlexRowItemsCenter } from '@/components/designSystem/Layout';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { ReactComponent as ToggleChevron } from '@/assets/toggle_chevron.svg';

export const NavbarContainer = styled.div`
  position: relative;
  display: flex;
  height: 4rem;
  flex-shrink: 0;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
`

export const StyledSearchContainer = styled.div`
  display: flex;
  padding: 0.5rem 1rem;

  @media ${device.laptop} {
    padding-left: 2rem;
    padding-right: 2rem;
  };

  @media ${device.mobileXL} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  };
`

export const StyledUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 1rem;
`

export const StyledUserButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const StyledUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 1rem;
`;

export const StyledCompany = styled(Caption)`
  color: ${({ theme }) => theme.color.greyMed};
`;

export const StyledName = styled(Caption)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.greyDark};
  word-wrap: break-word;
`;

export const StyledEmail = styled(Caption)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.greyDark};
  word-wrap: break-word;
`;

export const StyledDivider = styled.div`
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.blackTrans16};
`;

export const StyledItemContainer = styled.div`
  padding: 0.25rem 0rem;
`

export const StyledUserNavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 15px;
  font-weight: bold;

  &.active {
    color: ${({ theme }) => theme.color.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.color.text.primary};
    background-color: ${({ theme }) => theme.color.beige};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

export const StyledLogoutButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 15px;
  font-weight: bold;

  &:hover {
    background-color: ${({ theme }) => theme.color.beige};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

export const MobileLogoContainer = styled.div`
  display: none;
  width: 2.5rem;
  height: 2.5rem;

  @media ${device.mobileXL} {
    display: flex;
  };
`;

export const MobileLogo = styled(LogoMark)`
  display: none;
  width: 100%;
  height: 100%;

  @media ${device.laptop} {
    display: flex;
  };
`;