import React, { useState, useContext } from 'react';

import { getOr } from 'lodash/fp';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { LogoutModal } from '@/modals/LogoutModal';

import { NAV_ITEMS } from '@/constants';

import { SearchDropdown } from './Search';
import Dropdown from '@/components/Dropdown';
import { InitialAvatar } from '@/components/InitialAvatar';
import { UserInfo } from './UserInfo';

import {
  HiOutlineBell,
  HiMiniChevronDown,
  HiMiniAdjustmentsVertical,
  HiMiniArrowRightEndOnRectangle
} from "react-icons/hi2";

import {
  NavbarContainer,
  StyledUserContainer,
  StyledUserButton,
  StyledMenuContainer,
  StyledDivider,
  StyledItemContainer,
  StyledUserNavItem,
  StyledLogoutButton,
  MobileLogoContainer,
  MobileLogo
} from './styled';

export const Navbar = () => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const currentUserData = useContext(CurrentUserContext);  
  const currentCompanyData = useContext(CurrentCompanyContext);

  const currentCompany = getOr('', 'currentCompany.name', currentCompanyData);
  const name = getOr('Loading...', 'currentUser.name', currentUserData);
  const email = getOr('', 'currentUser.email', currentUserData);

  return (
    <NavbarContainer>
      <MobileLogoContainer>
        <MobileLogo />
      </MobileLogoContainer>
      <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
        <SearchDropdown />
      </div>

      <StyledUserContainer>
        {/* <Dropdown
          customButton={
            <button>
              <HiOutlineBell style={{ width: '1.75rem', height: '1.75rem' }} />
            </button>
          }
          options={[
            { label: 'Option 1' },
            { label: 'Option 2' },
            { label: 'Option 3' }
          ]}
          onSelect={(option) => console.log('Selected:', option)}
          position='right'
        /> */}
        <Dropdown
          customButton={
            <StyledUserButton>
              <InitialAvatar size={2} name={name} />
              <HiMiniChevronDown />
            </StyledUserButton>
          }
          position='right'
          width='14rem'
        >
          <StyledMenuContainer>
            <UserInfo
              // currentCompany={currentCompany}
              name={name}
              email={email}
            />
            <StyledDivider />
            <StyledItemContainer>
              <StyledUserNavItem
                to="/settings/"
                exact={false}
              >
                <HiMiniAdjustmentsVertical />
                {NAV_ITEMS.SETTINGS}
              </StyledUserNavItem>
              <StyledLogoutButton onClick={() => setLogoutModalOpen(true)}>
                <HiMiniArrowRightEndOnRectangle />
                {NAV_ITEMS.LOGOUT}
              </StyledLogoutButton>
            </StyledItemContainer>
          </StyledMenuContainer>
        </Dropdown>
      </StyledUserContainer>
      <LogoutModal isOpen={isLogoutModalOpen} setIsOpen={setLogoutModalOpen} />
    </NavbarContainer>
  );
};
