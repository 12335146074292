import React from 'react';

import styled from 'styled-components';

import { ReactComponent as LogoFull } from '@/assets/logo_full.svg';
import { ReactComponent as LogoMark } from '@/assets/logo_mark.svg';

import device from '@/styles/device';

const DesktopLogoContainer = styled.div`
  padding: 0.5rem 1.5rem;
  height: 4rem;

  @media ${device.laptop} {
    display: none;
  };
`

const TabletLogoContainer = styled.div`
  display: none;
  position: relative;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0.5rem 1rem;

  @media ${device.laptop} {
    display: flex;
  };

  @media ${device.mobileXL} {
    display: none;
  };
`;

const MobileLogoContainer = styled.div`
  display: none;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 0.6rem;
  left: 1rem;
  z-index: 3;

  @media ${device.mobileXL} {
    display: none;
  };
`;

const DesktopLogo = styled(LogoFull)`
  height: 100%;
  width: 100%;
`;

const MobileLogo = styled(LogoMark)`
  display: none;
  width: 100%;
  height: 100%;

  @media ${device.laptop} {
    display: flex;
  };
`;

export const Logo = () => (
  <>
    <DesktopLogoContainer>
      <DesktopLogo />
    </DesktopLogoContainer>
    <TabletLogoContainer>
      <MobileLogo />
    </TabletLogoContainer>
    <MobileLogoContainer>
      <MobileLogo />
    </MobileLogoContainer>
  </>
);
