import React, { useState, useRef, useEffect } from 'react';

import {
  DropdownContainer,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuItemSeparator
} from './styled';

interface IDropdownOptionProps {
  label: string;
  separator?: boolean;
}

interface IDropdownProps {
  customButton: React.ReactNode;
  options?: IDropdownOptionProps[];
  onSelect?: (option: string) => void;
  width?: string;
  color?: string;
  fontSize?: string;
  backgroundColor?: string;
  position?: 'left' | 'right';
  borderRadius?: string;
  zIndex?: number;
  upward?: boolean;
  children?: React.ReactNode;
}

const Dropdown: React.FC<IDropdownProps> = ({
  customButton,
  options,
  onSelect,
  width,
  color,
  fontSize,
  backgroundColor,
  position,
  borderRadius,
  zIndex,
  upward = false,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option: string) => {
    if (onSelect) {
      onSelect(option);
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      {React.cloneElement(customButton as React.ReactElement, {
        onClick: toggleDropdown,
      })}
      <DropdownMenu
        isOpen={isOpen}
        width={width}
        color={color}
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        position={position}
        borderRadius={borderRadius}
        zIndex={zIndex}
        upward={upward}
      >
        {options && options.length > 0 ? (
          options.map((option, index) => (
            <React.Fragment key={index}>
              <DropdownMenuItem onClick={() => handleOptionClick(option.label)}>
                {option.label}
              </DropdownMenuItem>
              {option.separator && <DropdownMenuItemSeparator />}
            </React.Fragment>
          ))
        ) : (
          children
        )}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
