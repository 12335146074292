import React, { createContext, useState, useContext } from 'react';
import { useLocalStorage, deleteFromStorage, writeStorage } from '@rehooks/local-storage';

interface CompanyContextType {
  companyId: string | null;
  setCompanyId: (id: string) => void;
}

const CompanyContext = createContext<CompanyContextType>({
  companyId: null,
  setCompanyId: () => {},
});

export const useCompany = () => useContext(CompanyContext);

export const CompanyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [companyId, setCompanyId] = useState<string | null>(null);

  const changeCompanyId = (id: string) => {
    setCompanyId(id);
    writeStorage('company-id', id);
  };

  return (
    <CompanyContext.Provider value={{ companyId, setCompanyId: changeCompanyId }}>
      {children}
    </CompanyContext.Provider>
  );
};
